import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { GlobalProvider } from "./context/GlobalContext";

const container = document.getElementById("root");

//@ts-ignore
const root = ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </React.StrictMode>,
  container
);
