import React, {useState} from "react";

import styles from "../styles/header.module.scss";
import MenuDrawer from "./MenuDrawer";

const Header = (props: any) => {
  const { roadmap, setRoadmap } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={styles.container}>
        <div className={styles.logoContainer}>
        <img
          onClick={() => setRoadmap(!roadmap)}
          src={"/Paragons_Type_Logo.svg"}
          alt={""}
        />
      </div>
      <div className={styles.navContainer}>
        <ul>
          <li>
            <button onClick={() => setRoadmap(!roadmap)}>{!roadmap ? "More Info": "Experience"}</button>
          </li>
          <li>
            <a
                href="https://mint.paragonsnft.com/"
                target="_blank"
                rel="noreferrer"
            >
              <div>Mint</div>
            </a>
          </li>
            <li>
                <a
                    href="https://whitepaper.paragonsnft.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <div>Whitepaper</div>
                </a>
            </li>
        </ul>
      </div>
        <div className={styles.launchButtonContainer}>
        <div className={styles.launchAppButton}>
            <a
                href="https://paraverse.paragonsnft.com/"
                target="_blank"
                rel="noreferrer"
            >
                <div>Paraverse</div>
            </a>
        </div>
      <div className={styles.launchAppButton}>
          <a
              href="https://app.paragonsnft.com/"
              target="_blank"
              rel="noreferrer"
          >
              <div>Launch App</div>
          </a>
      </div>
        </div>
    {menuOpen ? (
        <img
            className={`${styles.menuCloseIcon} ${styles.iconFade}`}
            alt="white cross"
            onClick={() => setMenuOpen(!menuOpen)}
            src="/cross.png"
        />
    ) : (
        <img
            onClick={() => setMenuOpen(!menuOpen)}
            className={`${styles.menuIcon} ${styles.iconFade}`}
            src="/menu.svg"
            alt="hamburger menu"
        />
    )}
    {menuOpen && <MenuDrawer roadmap={roadmap} setRoadmap={setRoadmap} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />}
    </div>
  );
};

export default Header;
