import React, { useEffect, useRef, useState } from "react";

import styles from "../styles/loader.module.scss";

const Loader = () => {
  const count = useRef(null);

  useEffect(() => {
    count.current = 1;
    const timer = setInterval(() => {
      count.current++;
    }, 500);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.loader}>
      <img className={styles.loaderImg} src="/Paragons_Icon_Logo.svg" alt="" />
      <div className={styles.loaderText}>
        Loading{".".repeat(count.current % 4)}
      </div>
    </div>
  );
};

export default Loader;
