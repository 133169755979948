import React, { useEffect, useState } from "react";

import styles from "../styles/MenuDrawer.module.scss";

const MenuDrawer = ({ menuOpen, setMenuOpen, setRoadmap, roadmap }: any) => {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    if (menuOpen) setFade(true);
  }, []);

  return (
    <div className={`${styles.container} ${fade ? styles.fadeIn : ""}`}>
      <ul>
        <li onClick={() => {
          setRoadmap(!roadmap);
          setMenuOpen(false)
        }}>
          <p>{!roadmap ? "More Info": "Experience"}</p>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a
              href="https://mint.paragonsnft.com/"
              target="_blank"
              rel="noreferrer">
            Mint
          </a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a
            href="https://whitepaper.paragonsnft.com/"
            target="_blank"
            rel="noreferrer">
            Whitepaper
          </a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a
              href="https://paraverse.paragonsnft.com/"
              target="_blank"
              rel="noreferrer">
            Paraverse
          </a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a
              href="https://app.paragonsnft.com/"
              target="_blank"
              rel="noreferrer">
            Launch App
          </a>
        </li>
      </ul>

      <small>©2022 Paragons NFT. All Rights Reserved.</small>
    </div>
  );
};

export default MenuDrawer;
