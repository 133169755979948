import React, { useState } from "react";

import styles from "../styles/notifications.module.scss";

const Notifications = () => {
  const [email, setEmail] = useState<string>("");
  const [discord, setDiscord] = useState<string>("");

  const handleEmailSubmission = async (event) => {
    event.preventDefault();
    if (!discord) {
      alert("Please enter your Discord username");
    }
    const res = await fetch(`https://api.paragonsnft.com/api/email/${email}`);
    const resJson = await res.json();
    if (resJson.success) {
      alert(`${resJson.message}

Discord Username: ${discord}`);
    } else {
      alert(resJson.error);
    }
  };

  return (
    <div className={styles.section}>
      <div className={styles.emailSection}>
        <h1>STAY UP TO DATE</h1>
        <h4>Get notified about our sale date</h4>
        <form onSubmit={handleEmailSubmission}>
          {/*<label htmlFor="femail">Email</label>*/}
          <input
            type="text"
            id="discord"
            name="discordname"
            placeholder="Your Discord Name Here.."
            value={discord}
            onChange={(e) => setDiscord(e.target.value)}
          />

          <input
            type="text"
            id="femail"
            name="email"
            placeholder="Your Email Here.."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input type="submit" value="Subscribe" />
        </form>
      </div>
      <div className={styles.joinDiscord}>
        <h1>JOIN THE PARAVERSE</h1>
        <h4>Come join our genuine community of Travelers !</h4>
        <div className={styles.discordButtonContainer}>
          <a
            className={styles.joinDiscordButton}
            href="https://discord.gg/paragonsnft"
            target="_blank"
          >
            JOIN OUR DISCORD
          </a>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
