import React, { useState } from "react";

import styles from "../styles/FAQ.module.scss";

const data = [
  {
    q: "When is the official launch?",
    a: "Our presale is January 28, 2022 8PM UTC. Our public sale will start 48 hours later.",
  },
  {
    q: "What will the mint price be?",
    a: "The presale and public sale mint prices will be 0.15 ETH.",
  },
  {
    q: "How do I get on the whitelist (presale list)?",
    a: "There will be many chances for people to join the whitelist (presale list) through events and raffles. Be sure to join our Discord and our Twitter for all the info.",
  },
  {
    q: "What am I getting with my Paragon NFT?",
    a: "Paragons are far from just a beautiful piece of art. They're your ticket to the Paraverse, an in-browser 3D world we're building for holders. Holding a Paragon also gets you several free NFTs including Shrines for your land, and Parables (creatures that inhabit the Paraverse).",
  },
  {
    q: "What exactly is the Paraverse?",
    a: "The Paraverse is a 3D in-browser world you get to explore as a Paragon holder. The Paraverse will be the landscape for interacting with your Paragon and also for harvesting resources.",
  },
];

const Drawer = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.faqItem}>
      <div onClick={() => setOpen(!open)} className={styles.questionBox}>
        <p>{data.q}</p>
        <img
          className={open ? styles.closeImg : styles.openImg}
          src="./cross.png"
        />
      </div>

      <div className={open ? styles.open : styles.close}>{data.a}</div>
    </div>
  );
};

const FAQ = () => {
  return (
    <>
      <div className={styles.container}>
        <h1>Frequently Asked Questions</h1>

        {data.map((x, id) => (
          <Drawer key={id} data={x} />
        ))}
      </div>
      <div className={styles.paragon} />
    </>
  );
};

export default FAQ;
