import React from "react";

import styles from "../styles/about.module.scss";

const About = () => {
  return (
    <div className={styles.container}>
      <h1>Meet The Team</h1>
      <div className={styles.grid}>
        <div className={styles.gridItemMain}>
          <img className={styles.image} src="/para22.jpg" alt="" />
          <div className={styles.topRowItems}>
            <h5>Juan (SPACE)</h5>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://twitter.com/spacerenders_"
            >
              <img className={styles.iconTwit} src="/twitterwhite.svg" alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.instagram.com/spacerenders/"
            >
              <img className={styles.iconInsta} src="/instawhite.svg" alt="" />
            </a>
          </div>
          <p>
            SPACE is an Ecuadorian artist focused on surrealist digital art.
            Standing at the intersection of tech and art, he has created a body
            of work that is meant to transcend the ordinary plane of existence.
            He’s been producing pieces in a prolific manner since 2015. Before
            venturing into the NFT world, SPACE worked on tour visuals for such
            names as Camila Cabello and Machine Gun Kelly. These works, along
            with his personal art pieces, propelled him into the NFT space to
            create pieces that have been sold on various marketplaces. He’s had
            NFT collab drops with Youtuber &amp; Boxer Jake Paul as well as
            Movie Music Composer Junkie XL.
          </p>
        </div>
        <div className={styles.gridItem}>
          <img className={styles.image} src="/para9.jpg" alt="" />
          <div className={styles.topRowItems}>
            <h5>Eugene (Paradox)</h5>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://twitter.com/__Paradox1__"
            >
              <img className={styles.iconTwit} src="/twitterwhite.svg" alt="" />
            </a>
          </div>
          <p>
            Experienced Blockchain and Web3 Developer previously at Non-Fungible
            Labs, he has worked in a wide range of industries including NFTs,
            Blockchain, 3D graphics, AI and the DJ Industry. Proudly leading the
            team into the Paraverse.
          </p>
        </div>
        <div className={styles.gridItem}>
          <img className={styles.image} src="/para21.jpg" alt="" />
          <div className={styles.topRowItems}>
            <h5>Tomas (Trevano)</h5>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.linkedin.com/in/tomasleriche"
            >
              <img className={styles.iconInsta} src="/linkedin.svg" alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://twitter.com/CryptoChanges"
            >
              <img
                className={styles.iconInsta}
                src="/twitterwhite.svg"
                alt=""
              />
            </a>
          </div>
          <p>
            Senior full-stack software engineer with experience in creative web
            development, web3, and 3D tech. Helping to bring the Paraverse to
            life.
          </p>
        </div>
        <div className={styles.gridItem}>
          <img className={styles.image} src="/para20.jpg" alt="" />
          <div className={styles.topRowItems}>
            <h5>Cassandra (CRIV)</h5>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.instagram.com/cassarivera"
            >
              <img className={styles.iconInsta} src="/instawhite.svg" alt="" />
            </a>
          </div>
          <p>
            UX/UI designer, content creator/designer, and WOMAN in the NFT
            Space.
          </p>
        </div>
        <div className={styles.gridItem}>
          <img className={styles.image} src="/para27.jpg" alt="" />

          <div className={styles.topRowItems}>
            <h5>Travis (TheTravisWong)</h5>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.instagram.com/theTravisWong"
            >
              <img className={styles.iconInsta} src="/instawhite.svg" alt="" />
            </a>
          </div>
          <p>
            Crypto investor & entrepreneur who launched Joining All Movement and
            brought Airwheel Global LTD to the USA in 2015. A former motion
            capture performance artist for Disney’s “The Jungle Book” & the
            “Call of Duty” franchise, as well current investor in Nguyen Coffee
            Supply and MOON UltraLight winner of TIME’s “100 Best Inventions of
            2020.”
          </p>
        </div>
        <div className={styles.gridItem}>
          <img className={styles.image} src="/para26.jpg" alt="" />

          <div className={styles.topRowItems}>
            <h5>Carly (C.DIAMOND)</h5>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.instagram.com/carlydiamondstone/"
            >
              <img className={styles.iconInsta} src="/instawhite.svg" alt="" />
            </a>
          </div>
          <p>
            Former brain researcher for UCLA turned actress, model, and crypto-
            enthusiast. Background in marketing and social media, and WOMAN in
            the NFT space.
          </p>
        </div>
        <div className={styles.gridItem}>
          <img className={styles.image} src="/para25.jpg" alt="" />

          <div className={styles.topRowItems}>
            <h5>Bailey (Bagels)</h5>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.instagram.com/bagels_payne/"
            >
              <img className={styles.iconInsta} src="/instawhite.svg" alt="" />
            </a>
          </div>
          <p>
            Crypto investor, professional social media influencer, and the first
            Red Bull sponsored tricking athlete in history, who has amassed 4+
            million followers across his social media platforms. A former world
            record holder, world champion tumbler, and member of Team RAR with
            18+ million subscribers collectively on Youtube.
          </p>
        </div>
        <div className={styles.gridItemMain2}>
          <img className={styles.image} src="/Paragon111.jpg" alt="" />

          <div className={styles.topRowItems}>
            <h5>Nelson (TheRulesGuy)</h5>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://twitter.com/TRGNFT"
            >
              <img
                className={styles.iconInsta}
                src="/twitterwhite.svg"
                alt=""
              />
            </a>
          </div>
          <p>
            Helped Steve Jobs design the first iPhone. Once told Jeff Bezos to
            suck it. Elon Musk regularly calls him for battery advice. Pretty
            much created Web3. Is a Pisces that loves to cuddle with bull dogs
            and watch 'This Is Us'. Find me on Tinder.
          </p>
        </div>
      </div>
      <div className={styles.divider} />
    </div>
  );
};

export default About;
