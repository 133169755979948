/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState, useRef } from "react";
import { useGLTF, PerspectiveCamera, useAnimations } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useSpring, animated, config } from "@react-spring/three";

export default function Model(props) {
  const [spinSpeed, setSpinSpeed] = useState(false);
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/red_planet.glb");
  const { actions } = useAnimations(animations, group);

  const { speed } = useSpring({
    speed: spinSpeed ? 0.0055 : 0.005,
    config: config.wobbly,
  });

  useFrame((state, delta) => {
    group.current.rotation.y = state.clock.elapsedTime * 0.1;
  });
  return (
    <animated.group {...props} scale={speed} dispose={null}>
      <group position={[0, 0, 0]} scale={375}>
        <mesh
          ref={group}
          onPointerOver={() => setSpinSpeed(true)}
          onPointerOut={() => setSpinSpeed(false)}
          geometry={nodes.Sphere.geometry}
          material={materials["Mat"]}
          rotation={[-0.19, -1.12, -0.15]}
        />
      </group>
    </animated.group>
  );
}

useGLTF.preload("/red_planet.glb");
