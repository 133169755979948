import React from "react";

import styles from "../styles/copyright.module.scss";

const Copyright = () => {
  return (
    <div className={styles.section}>
        <div className={styles.copyrightContainer}>
          <img src={"/Paragons_Type_Logo.svg"} alt={""} width={200} height={30} />
        <div className={styles.divider}/>
        <div className={styles.copyright}>
          © 2021 Paragons | All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default Copyright;
