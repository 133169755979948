import React, { useState } from "react";

import FAQ from "../components/FAQ";
import styles from "../styles/roadmap.module.scss";
import Notifications from "./Notifications";
import Copyright from "./Copyright";
import About from "./About";

const RoadmapRegular = ({ roadmap }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={`${styles.outer} ${roadmap ? styles.show : styles.hide}`}>
      <div className={styles.cover}>
        <div className={styles.grid}>
          <div className={styles.titleSection}>
            <h1>ROADMAP</h1>
            <p>3D Web Application Experience with Play to Earn Mechanics</p>
          </div>
          <div className={styles.imageCenter}></div>
          <div className={styles.col1}>
            <div className={styles.first1}>
              <h4>PARAGON GENESIS</h4>
              <p>
                Initial Minting of 3424 Paragons as part of the Phase 1 release.
              </p>
            </div>
            <div className={styles.second1}>
              <h4>SHRINE NFTs</h4>
              <p>
                All holders will be able to mint a free Shrine NFT which will
                yield resources on their land daily that they can harvest.
              </p>
            </div>
            <div className={styles.third1}>
              <h4>TOKEN</h4>
              <p>
                An ERC-20 token will be released to holders. All holders can now
                convert their gathered resources into Tokens.
              </p>
            </div>
            <div className={styles.fourth1}>
              <h4>STAKING</h4>
              <p>
                Holders will be able to stake their NFTs to earn Tokens
                passively. Paragons and Parables can be staked.
              </p>
            </div>
            <div className={styles.fifth1}>
              <h4>PARAVERSE EVOLUTION</h4>
              <p>
                Team will continue to add value into the Paraverse as well as
                future drops, functionality for resources and Tokens.
              </p>
            </div>
          </div>
          <div className={styles.col2}>
            <div className={styles.first}>
              <h4>RELIC NFTs</h4>
              <p>
                All holders will be able to mint a free Relic per Paragon.
                Relics will have more utility based on the Paragon's Token Id.
              </p>
            </div>
            <div className={styles.second}>
              <h4>PARAGON LANDSCAPES</h4>
              <p>
                Landscapes are given to all Paragon holders. Holders can access
                and explore this in the browser. The landscape will reflect the
                aesthetic of their Paragon.
              </p>
            </div>
            <div className={styles.third}>
              <h4>PARABLE GUARDIANS NFTS</h4>
              <p>
                A limited number of Parables will have the chance to be minted
                with the resources holders have gathered and the Tokens they
                have collected.
              </p>
            </div>
            <div className={styles.fourth}>
              <h4>PORTALS</h4>
              <p>
                Holders will be able to travel to other Paragon’s landscapes to
                trade resources or just hang out.
              </p>
            </div>
          </div>
        </div>
        <FAQ />
        <Notifications />
        <About />
        <Copyright />
      </div>
    </div>
  );
};

export default RoadmapRegular;
