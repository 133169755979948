import React, {useRef, useState} from 'react'
import {useGLTF, useScroll} from '@react-three/drei'
import * as THREE from "three";
import {useFrame} from "@react-three/fiber";

export default function Model(props) {
    const [x, y, z] = props.position;
    const group = useRef();
    const scroll = useScroll();
    const [closeToCamera, setCloseToCamera] = useState(true);
    const thisPos = new THREE.Vector3(x, y, z);

    useFrame((state) => {

        if (state.camera.position.distanceTo(thisPos) > 300) {
            setCloseToCamera(false);
        } else {
            setCloseToCamera(true);
        }
    });

    const { nodes, materials } = useGLTF('/Paragons_Type_Logo.gltf')
    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.Type_Logo.geometry}
                material={materials['default']}
                visible={closeToCamera}
            />
        </group>
    )
}

useGLTF.preload('/Paragons_Type_Logo.gltf')