import React from "react";

import styles from "../styles/footer.module.scss";

interface Props {}

const Footer = (props: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.shareContainer}>
        <a
          href="https://discord.gg/paragonsnft"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/discordwhite.svg" alt="" />
        </a>
        <a
          href="https://twitter.com/ParagonsNFT"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/twitterwhite.svg" alt="" />
        </a>
        <a
          href="https://www.instagram.com/paragonsnft"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/instawhite.svg" alt="" />
        </a>
        <a
          href="https://medium.com/@ParagonsNFT/how-to-mint-your-paragons-nft-598b63b9b30d"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/medium.png" alt="Medium Icon" />
        </a>
        <a
          href="https://opensea.io/collection/paragons-official"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/opensea.png" alt="Opensea Icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
