import React, { useRef, useEffect, useState } from "react";
import { useGLTF, PerspectiveCamera, useAnimations } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function Model(props) {
  const group = useRef();
  const rings = useRef();
  const [paraLoaded, setParaLoaded] = useState(false);

  const { nodes, materials, animations } = useGLTF("/paragon.glb");
  useFrame((state, delta) => {
    rings.current.rotation.y = state.clock.elapsedTime * 0.3;
    if (!paraLoaded) {
      props.loadedCallback(false);
      setParaLoaded(true);
    }
  });

  useEffect(() => {}, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[1367.34, -1735.31, -21851.7]} rotation={[0, 0.77, 0]}>
        <group
          ref={rings}
          position={[367.93, 4730.69, -1908.3]}
          rotation={[-0.27, -0.27, -0.17]}
          scale={[100.48, 100.48, 100.48]}
        >
          <group
            position={[0, 0, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[4.27, 4.27, 4.27]}
          >
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Saturn_Rings.geometry}
              material={nodes.Saturn_Rings.material}
            />
          </group>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Ring_Asteroids.geometry}
            material={materials["Mat.1"]}
            position={[0, 0, 0]}
            rotation={[0.31, -0.46, 0.11]}
            scale={[0.01, 0.01, 0.01]}
          />
        </group>
        <group
          position={[-71.34, -3764.69, -148.3]}
          rotation={[0, -0.05, 0]}
          scale={[100, 100, 100]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Boole.geometry}
            material={nodes.Boole.material}
            position={[-15.51, -363.97, 0]}
            rotation={[0, -0.72, 0]}
            scale={[0.01, 0.01, 0.01]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Boole_1.geometry}
            material={nodes.Boole_1.material}
            position={[-84.6, -154.5, 78.4]}
            rotation={[0, -0.72, 0]}
            scale={[0.01, 0.01, 0.01]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/paragon.glb");
