import { TextureLoader } from "three/src/loaders/TextureLoader";
import { Canvas, useLoader } from "react-three-fiber";
import { useRef, useState, useEffect } from "react";
import { useTexture } from "@react-three/drei";

import { useSpring, animated, config } from "@react-spring/three";

export function ImageComponent1({ img, args = [20, 20], ...props }) {
  const [active, setActive] = useState(false);
  const ready = useRef();

  const propss = useTexture({ map: img });
  const { scale } = useSpring({
    scale: active ? 1.5 : 1,
  });

  useEffect(() => {
    ready.current = true;
  }, []);

  return (
    <animated.mesh
      {...props}
      onPointerEnter={() => {
        ready.current && setActive(true);
      }}
      onPointerLeave={() => {
        ready.current && setActive(false);
      }}
      scale={scale}
    >
      <planeBufferGeometry attach="geometry" args={args} />
      <meshBasicMaterial attach="material" {...propss} />
    </animated.mesh>
  );
}
