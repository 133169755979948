import { createContext, useContext, useState } from "react";

const GlobalContext = createContext({
  showDebug: true,
  setShowDebug: null,
});

const GlobalProvider = ({ children }) => {
  const [showDebug, setShowDebug] = useState(false);

  const value = {
    showDebug,
    setShowDebug,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be within the GlobalProvider");
  }
  return context;
};

export { GlobalProvider, useGlobalContext };
