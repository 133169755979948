//@ts-nocheck
import React, { useState, useRef, Suspense, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  OrbitControls,
  Stars,
  Html,
  useProgress,
  Text,
  ScrollControls,
  useScroll,
  AdaptiveDpr,
} from "@react-three/drei";
import * as THREE from "three";

import { useSpring, animated, config } from "@react-spring/three";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import "./App.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "react-dat-gui/dist/index.css";
import Media from "react-media";

import styles from "./styles/home.module.scss";

import Header from "./components/header";
import Footer from "./components/footer";

import Planet from "./components/RedPlanet";
import Paragon from "./components/Paragon";
import Logo from "./components/ParagonTypeLogo";
import { ImageComponent1 } from "./components/Image";
import RoadmapRegular from "./components/Roadmap";
import Loader from "./components/Loader";

const ImagesArray = [
  "/para1.jpg",
  "/para2.jpg",
  "/para3.jpg",
  "/para4.jpg",
  "/para5.jpg",
  "/para6.jpg",
  "/para7.jpg",
  "/para8.jpg",
  "/para9.jpg",
  "/para10.jpg",
  "/para11.jpg",
  "/para12.jpg",
  "/para13.jpg",
  "/para14.jpg",
  "/para15.jpg",
  "/para16.jpg",
  "/para17.jpg",
  "/para18.jpg",
  "/para19.jpg",
];

gsap.registerPlugin(ScrollTrigger);

function TextObject({
  font,
  textAlign,
  letterSpacing,
  lineHeight,
  maxWidth,
  text,
  color,
  fontSize,
  distanceThreshold = 120,
  ...props
}) {
  const { position } = props;
  const [closeToCamera, setCloseToCamera] = useState(true);
  const [x, y, z] = position;
  const thisPos = new THREE.Vector3(x, y, z);
  useFrame((state, delta) => {
    if (state.camera.position.distanceTo(thisPos) > distanceThreshold) {
      setCloseToCamera(false);
    } else {
      setCloseToCamera(true);
    }
  });

  return (
    //@ts-nocheck
    <animated.group>
      <Text
        fillOpacity={closeToCamera ? 1 : 0}
        color={color}
        fontSize={fontSize}
        maxWidth={maxWidth}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
        textAlign={textAlign}
        font={font}
        anchorX="center"
        anchorY="middle"
        {...props}
      >
        {text}
      </Text>
    </animated.group>
  );
}

const Light = ({
  lightColor,
  ambientLightIntesity,
  directLightIntensity,
  directLightIntensity2,
  position2,
  castShadow,
  ...props
}) => {
  const ref = useRef();
  const { position } = props;
  const [lightIntensity2, setLightIntensity2] = useState(directLightIntensity2);
  const [x, y, z] = position;
  const thisPos = new THREE.Vector3(x, y, z);

  useFrame((state, delta) => {
    const posFromBeginning = state.camera.position.distanceTo(thisPos) / 3;
    if (directLightIntensity2 - posFromBeginning > 1) {
      setLightIntensity2(directLightIntensity2 - posFromBeginning);
    }
  });
  return (
    <>
      <ambientLight color={lightColor} intensity={ambientLightIntesity} />
      <directionalLight
        {...props}
        ref={ref}
        color={lightColor}
        intensity={directLightIntensity}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        castShadow={castShadow}
      />
      <directionalLight
        {...props}
        ref={ref}
        color={lightColor}
        intensity={lightIntensity2}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        castShadow={castShadow}
        position={position2}
      />
    </>
  );
};

function ImageCircle({ ...props }) {
  const group = useRef();
  const mesh = useRef();

  useFrame((state) => {
    group.current.rotation.y = state.clock.elapsedTime * 0.2;
  });

  return (
    <group ref={group} rotation={[-0.1, 0, 0]} position={[-172, 0, 350]}>
      <mesh ref={mesh} {...props}>
        <ImageComponent1
          position={[0, 0, -6]}
          rotation-y={(2 * -Math.PI) / 23}
          img={ImagesArray[0]}
        />

        <ImageComponent1
          position={[-22, 0, -18.4]}
          rotation-y={(2 * -Math.PI) / 10}
          img={ImagesArray[1]}
        />

        <ImageComponent1
          position={[-38, 0, -40]}
          rotation-y={-Math.PI / 3.1}
          img={ImagesArray[3]}
        />
        <ImageComponent1
          position={[-48, 0, -63]}
          rotation-y={-Math.PI / 2.2}
          img={ImagesArray[17]}
        />
        <ImageComponent1
          position={[-47, 0, -88]}
          rotation-y={-Math.PI / 1.8}
          img={ImagesArray[18]}
        />
        <ImageComponent1
          position={[-38, 0, -108]}
          rotation-y={-Math.PI / 1.5}
          img={ImagesArray[16]}
        />

        <ImageComponent1
          position={[24, 0, -3]}
          rotation-y={(-2 * Math.PI) / 1}
          img={ImagesArray[2]}
        />

        <ImageComponent1
          position={[46, 0, -6.8]}
          rotation-y={Math.PI / 8}
          img={ImagesArray[4]}
        />
        <ImageComponent1
          position={[68, 0, -17.5]}
          rotation-y={Math.PI / 5.6}
          img={ImagesArray[6]}
        />
        <ImageComponent1
          position={[85, 0, -33.5]}
          rotation-y={(2 * Math.PI) / 7}
          img={ImagesArray[5]}
        />
        <ImageComponent1
          position={[96, 0, -55.5]}
          rotation-y={(2 * Math.PI) / 5}
          img={ImagesArray[7]}
        />
        <ImageComponent1
          position={[100, 0, -78.5]}
          rotation-y={Math.PI / 2}
          img={ImagesArray[8]}
        />
        <ImageComponent1
          position={[95, 0, -100.5]}
          rotation-y={(2 * Math.PI) / 3.2}
          img={ImagesArray[9]}
        />
        <ImageComponent1
          position={[83, 0, -119.5]}
          rotation-y={(2 * Math.PI) / 2.8}
          img={ImagesArray[10]}
        />
        <ImageComponent1
          position={[67, 0, -134.5]}
          rotation-y={(2 * Math.PI) / 2.45}
          img={ImagesArray[11]}
        />
        <ImageComponent1
          position={[46, 0, -145.5]}
          rotation-y={(2 * Math.PI) / 2.2}
          img={ImagesArray[12]}
        />
        <ImageComponent1
          position={[22, 0, -147.5]}
          rotation-y={(2 * Math.PI) / 2}
          img={ImagesArray[13]}
        />
        <ImageComponent1
          position={[-2, 0, -140.5]}
          rotation-y={(2 * Math.PI) / 1.8}
          img={ImagesArray[14]}
        />
        <ImageComponent1
          position={[-23, 0, -126.5]}
          rotation-y={(2 * Math.PI) / 1.6}
          img={ImagesArray[15]}
        />
      </mesh>
    </group>
  );
}

const MouseRig = ({ children, OrbitalControls }) => {
  const ref = useRef() as any;
  const vec = new THREE.Vector3();

  const { mouse } = useThree();

  useFrame((state, delta) => {
    if (!OrbitalControls) {
      ref.current.position.lerp(vec.set(mouse.x * 1, mouse.y * 1, 0), 0.1);
      ref.current.rotation.y = THREE.MathUtils.lerp(
        ref.current.rotation.y,
        (-mouse.x * Math.PI) / 480,
        0.1
      );
    }
  });
  return <group ref={ref}>{children}</group>;
};

const CameraRig = ({ children, OrbitalControls, roadmap, fov }) => {
  const scroll = useScroll();

  const originVec = new THREE.Vector3();

  const lookOGVec = new THREE.Vector3();
  const newViewVec = new THREE.Vector3();

  useFrame((state, delta) => {
    if (!OrbitalControls) {
      // if (!roadmap) {
      const offset = scroll.offset;

      const x = offset * -100;
      const y = offset * 10;
      const z = offset * 630;
      state.camera.fov = fov;

      const lookx =
        -240 * offset ** 2 +
        1 * Math.cos(state.clock.elapsedTime / 2) * offset * 6;
      const looky = 15 + 1 * Math.sin(state.clock.elapsedTime) * offset * 6;
      const lookz = -15;

      lookOGVec.set(lookx, looky, lookz);
      newViewVec.set(
        25,
        0 + 1 * Math.sin(state.clock.elapsedTime / 4),
        0 + 1 * Math.cos(state.clock.elapsedTime / 4)
      );

      state.camera.position.set(x, y, z);
      state.camera.lookAt(lookOGVec);

      state.camera.updateProjectionMatrix();
    }
  });
  return <group>{children}</group>;
};

/// ------------------------------- MAIN APP -------------------------------

function App() {
  // const { showDebug, setShowDebug } = useGlobalContext();
  const [rotation, setRotation] = useState([0, 0, 0, 0]);
  const [roadmap, setRoadmap] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const offset = [-24, 0, 71];
  const { progress } = useProgress();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const [opts, setOpts] = useState({
    lightColor: "#edaff0",
    // intensity: 1,
    ambientLightIntensity: 0.25,
    directLightIntensity: 3.93,
    directLightIntensity2: 11,
    castShadow: false,
    lightx: -15.1,
    lighty: 13.8,
    lightz: 0.1,
    light2x: -10.6,
    light2y: -6,
    light2z: 6,
    paragonx: 14,
    paragony: 2,
    paragonz: 266,
    paragonRotate: 0,
    paragonScale: 0.023,
    fontSize: window.innerWidth < 500 ? 0.8 : 1.7,
    letterSpacing: 0.05,
    textColor: "#ffffff",
    textAlign: "center",
    fontStyle: "/TussilagoHv-Regular.otf",
    lineHeight: 1.27,
    maxWidth: window.innerWidth < 500 ? 10 : 26,
    fov: 39,
    isCameraShake: false,
    OrbitalControls: false,
    BGColor: "#1E1E1E",
    lockScroll: false,
    kernelSize: 4,
    luminanceThreshold: 0.0,
    luminanceSmoothing: 0.2,
    bloomIntensity: 0.1,
    isBloomDisabled: true,
  });

  return (
    <div className="App">
      <Header roadmap={roadmap} setRoadmap={setRoadmap} />
      <RoadmapRegular roadmap={roadmap} />
      {isLoading && <Loader />}
      <Canvas
        camera={{ position: [0, 0, 0], rotation: [0, 0, 0], fov: opts.fov }}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: opts.BGColor,
        }}
        dpr={[1, 2]}
        onCreated={() => {
          console.info("Canvas loaded");
        }}
        onMouseEnter={() => {
          console.info("Canvas loaded");
        }}
        performance={{ min: 0.5 }}
        gl={{ alpha: false, antialias: false }}
        mode="concurrent"
      >
        <AdaptiveDpr pixelated />
        <Suspense fallback={null}>
          <Stars radius={0} factor={6} depth={300} />
          <Light
            lightColor={opts.lightColor}
            position={[opts.lightx, opts.lighty, opts.lightz]}
            position2={[opts.light2x, opts.light2y, opts.light2z]}
            castShadow={opts.castShadow}
            directLightIntensity={opts.directLightIntensity}
            directLightIntensity2={opts.directLightIntensity2}
            ambientLightIntesity={opts.ambientLightIntensity}
          />
          <Suspense fallback={null}>
            <ScrollControls pages={5}>
              <CameraRig
                roadmap={roadmap}
                OrbitalControls={opts.OrbitalControls}
                fov={opts.fov}
              >
                <>
                  <Paragon
                    loadedCallback={setIsLoading}
                    scale={opts.paragonScale}
                    position={[opts.paragonx, opts.paragony, opts.paragonz]}
                    rotation-y={opts.paragonRotate}
                  />
                  <Media
                    queries={{
                      small: { maxWidth: 550 },
                      medium: { maxWidth: 800 },
                    }}
                  >
                    {(matches) => {
                      if (matches.small) {
                        return (
                          <Logo
                            scale={0.1}
                            position={[4, 190, -155]}
                            rotation-x={Math.PI / 4}
                          />
                        );
                      } else if (matches.medium) {
                        return (
                          <Logo
                            scale={0.16}
                            position={[7, 190, -155]}
                            rotation-x={Math.PI / 4}
                          />
                        );
                      } else {
                        return (
                          <Logo
                            scale={0.3}
                            position={[12, 190, -155]}
                            rotation-x={Math.PI / 4}
                          />
                        );
                      }
                    }}
                  </Media>

                  <Planet scale={0.1} position={[-170, 0, 350]} />
                  <ImageCircle imgs={ImagesArray} position={offset} />
                </>
              </CameraRig>
            </ScrollControls>
          </Suspense>
          <MouseRig OrbitalControls={opts.OrbitalControls}>
            <>
              <Media
                queries={{
                  small: { maxWidth: 550 },
                  medium: { maxWidth: 800 },
                }}
              >
                {(matches) => {
                  if (matches.small) {
                    return (
                      <TextObject
                        rotation-x={Math.PI / 4}
                        fontSize={2}
                        position={[0, 69, -65]}
                        text={
                          !(progress < 100)
                            ? "SCROLL TO EXPLORE"
                            : `Loading... ${progress}%`
                        }
                        distanceThreshold={100}
                        color={opts.textColor}
                        font={opts.fontStyle}
                        textAlign={"center"}
                        letterSpacing={opts.letterSpacing}
                        lineHeight={1}
                        maxWidth={30}
                      />
                    );
                  } else if (matches.medium) {
                    return (
                      <TextObject
                        rotation-x={Math.PI / 4}
                        fontSize={1.6}
                        position={[0, 69, -65]}
                        text={
                          !(progress < 100)
                            ? "SCROLL TO EXPLORE"
                            : `Loading... ${progress}%`
                        }
                        distanceThreshold={100}
                        color={opts.textColor}
                        font={opts.fontStyle}
                        textAlign={"center"}
                        letterSpacing={opts.letterSpacing}
                        lineHeight={1}
                        maxWidth={30}
                      />
                    );
                  } else {
                    return (
                      <TextObject
                        rotation-x={Math.PI / 4}
                        fontSize={3}
                        position={[0, 60, -65]}
                        text={
                          !(progress < 100)
                            ? "SCROLL TO EXPLORE"
                            : `Loading... ${progress}%`
                        }
                        distanceThreshold={100}
                        color={opts.textColor}
                        font={opts.fontStyle}
                        textAlign={"center"}
                        letterSpacing={opts.letterSpacing}
                        lineHeight={1}
                        maxWidth={50}
                      />
                    );
                  }
                }}
              </Media>
              <Media
                queries={{
                  small: { maxWidth: 550 },
                  medium: { maxWidth: 800 },
                }}
              >
                <TextObject
                  rotation={rotation}
                  fontSize={opts.fontSize}
                  position={[-18, 4, 100]}
                  text="9,424 UNIQUE 3D GENERATED NFTs. METAVERSE READY"
                  color={opts.textColor}
                  font={opts.fontStyle}
                  textAlign={opts.textAlign}
                  letterSpacing={opts.letterSpacing}
                  lineHeight={opts.lineHeight}
                  maxWidth={opts.maxWidth}
                />
              </Media>
              <TextObject
                rotation={rotation}
                fontSize={opts.fontSize}
                position={[-36, 3, 200]}
                text="A PLAY-TO-EARN IMMERSIVE EXPERIENCE CALLED THE PARAVERSE"
                color={opts.textColor}
                font={opts.fontStyle}
                textAlign={opts.textAlign}
                letterSpacing={opts.letterSpacing}
                lineHeight={opts.lineHeight}
                maxWidth={opts.maxWidth}
              />
              <TextObject
                rotation={rotation}
                fontSize={opts.fontSize}
                position={[-62, 4, 350]}
                text="FUTURE NFT DROPS, STAKING, AND TOKEN-FUELED ECONOMY"
                color={opts.textColor}
                font={opts.fontStyle}
                textAlign={opts.textAlign}
                letterSpacing={opts.letterSpacing}
                lineHeight={opts.lineHeight}
                maxWidth={opts.maxWidth}
              />
              <TextObject
                rotation={rotation}
                fontSize={1.1}
                position={[-84, 4, 470]}
                text="COMMUNITY POWERED PROJECT WITH COMMUNITY VAULT AND DAO"
                color={opts.textColor}
                font={opts.fontStyle}
                textAlign={opts.textAlign}
                letterSpacing={opts.letterSpacing}
                lineHeight={opts.lineHeight}
                maxWidth={opts.maxWidth}
              />
              <TextObject
                rotation={rotation}
                fontSize={2.2}
                position={[-110, 2, 564]}
                text="MINTING PHASE 1 LIVE NOW"
                color={opts.textColor}
                font={opts.fontStyle}
                textAlign={opts.textAlign}
                letterSpacing={opts.letterSpacing}
                lineHeight={opts.lineHeight}
                maxWidth={opts.maxWidth}
              />
            </>
          </MouseRig>

          {opts.OrbitalControls && (
            <OrbitControls
              enableDamping={true}
              enablePan={true}
              maxDistance={1000}
              minDistance={6}
              addEventListener={undefined}
              hasEventListener={undefined}
              removeEventListener={undefined}
              dispatchEvent={undefined}
            />
          )}

          {!opts.isBloomDisabled && (
            <EffectComposer multisampling={8}>
              <Bloom
                kernelSize={opts.kernelSize}
                luminanceThreshold={opts.luminanceThreshold}
                luminanceSmoothing={opts.luminanceSmoothing}
                intensity={opts.bloomIntensity}
              />
            </EffectComposer>
          )}
        </Suspense>
      </Canvas>

      <Footer />
      {/* {showDebug && (
        <div style={{ position: "relative", zIndex: 2 }}>
          <DatGui data={opts} onUpdate={setOpts}>
            <DatFolder closed title="Light">
              <DatNumber
                path="ambientLightIntensity"
                min={0}
                max={1}
                step={0.01}
              />
              <DatNumber
                path="directLightIntensity"
                min={0}
                max={20}
                step={0.01}
              />
              <DatNumber
                path="directLightIntensity2"
                min={0}
                max={20}
                step={0.01}
              />
              <DatColor path="lightColor" />
              <DatBoolean path="castShadow" label="castShadow" />
              <DatNumber path="lightx" min={-40} max={40} step={0.1} />
              <DatNumber path="lighty" min={-40} max={40} step={0.1} />
              <DatNumber path="lightz" min={-40} max={40} step={0.1} />
              <DatNumber path="light2x" min={-40} max={40} step={0.1} />
              <DatNumber path="light2y" min={-40} max={40} step={0.1} />
              <DatNumber path="light2z" min={-2000} max={2000} step={0.1} />
            </DatFolder>
            <DatFolder closed title="Paragon">
              <DatNumber path="paragonx" min={-400} max={400} step={2} />
              <DatNumber path="paragony" min={-400} max={400} step={2} />
              <DatNumber path="paragonz" min={-400} max={400} step={2} />
              <DatNumber
                path="paragonRotate"
                min={-Math.PI}
                max={Math.PI}
                step={Math.PI / 16}
              />
              <DatNumber
                path="paragonScale"
                min={0.005}
                max={0.1}
                step={0.001}
              />
            </DatFolder>
            <DatFolder closed title="Text">
              <DatNumber path="fontSize" min={0.1} max={10} step={0.1} />
              <DatNumber path="letterSpacing" min={0.0} max={0.5} step={0.01} />
              <DatNumber path="maxWidth" min={5} max={400} step={0.5} />
              <DatNumber path="lineHeight" min={0.5} max={2} step={0.01} />
              <DatColor path="textColor" />
              <DatSelect
                path="textAlign"
                options={["left", "center", "right", "justify"]}
              />
              <DatSelect
                path="fontStyle"
                options={[
                  "/TussilagoHv-Italic.otf",
                  "/TussilagoHv-Regular.otf",
                  "/TussilagoRg-Regular.otf",
                ]}
              />
            </DatFolder>
            <DatFolder closed title="Bloom Effect">
              <DatBoolean path="isBloomDisabled" label="Disable" />
              <DatNumber path="kernelSize" min={0} max={5} step={1} />
              <DatNumber path="luminanceThreshold" min={0} max={5} step={0.1} />
              <DatNumber path="luminanceSmoothing" min={0} max={5} step={0.1} />
              <DatNumber path="bloomIntensity" min={0} max={5} step={0.1} />
            </DatFolder>
            <DatFolder closed title="Camera">
              <DatButton
                label="Toggle CameraShake"
                onClick={() =>
                  setOpts({ ...opts, isCameraShake: !opts.isCameraShake })
                }
              />
              <DatButton
                label="Toggle OrbitalControls"
                onClick={() =>
                  setOpts({ ...opts, OrbitalControls: !opts.OrbitalControls })
                }
              />
              <DatNumber path="fov" min={15} max={75} step={0.5} />
            </DatFolder>
            <DatColor path="BGColor" />
          </DatGui>
        </div>
      )} */}
    </div>
  );
}

export default App;
